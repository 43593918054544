import * as React from "react"
import Layout from "src/components/layout"
import { Machine, assign, spawn } from "xstate"
import { useMachine, useActor } from "@xstate/react"
import { useStrapiProducts } from "src/hooks/use-strapi-products"

const moneyFormat = NUMBER =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
    NUMBER
  )

const productMachineFactory = ctx => {
  const { id, title, supply_size, revenue_share, new_customers_weekly } = ctx
  return Machine({
    id: "product",
    initial: "ready",
    context: {
      id,
      title,
      supply_size: parseInt(supply_size.replace("days", "")),
      revenue_share,
      new_customers_weekly,
      new_autoship_monthly: 1,
    },
    states: {
      ready: {},
    },
    on: {
      CUSTOMERS_UPDATE: {
        actions: [
          assign({
            new_customers_weekly: (context, event) => event.value,
          }),
        ],
      },
      AUTOSHIP_UPDATE: {
        actions: [
          assign({
            new_autoship_monthly: (context, event) => event.value,
          }),
        ],
      },
    },
  })
}

const calculatorMachineFactory = products =>
  Machine({
    id: "calculator",
    context: {
      products,
      // filter: "all",
    },
    initial: "loading",
    states: {
      loading: {
        entry: assign({
          products: context => {
            // "Rehydrate" persisted todos
            return context.products.map(product => ({
              ...product,
              ref: spawn(productMachineFactory(product), { sync: true }),
            }))
          },
        }),
        always: "ready",
      },
      ready: {},
    },
    on: {},
  })

const Product = ({ product }) => {
  console.log("PRODUCT", product)
  const [state, send] = useActor(product.ref)
  if (!product) {
    return <tr></tr>
  }
  const {
    id,
    title,
    new_customers_weekly,
    new_autoship_monthly,
    revenue_share,
    supply_size,
  } = state.context
  return (
    <tr>
      <th scope="row">{title}</th>
      <td>~imageplz~</td>
      <td>
        <input
          type="number"
          id={`customers-${id}`}
          className="form-control"
          value={new_customers_weekly}
          // value={new_customers_weekly}
          onChange={e => {
            console.log(e.target.value)
            send({ type: "CUSTOMERS_UPDATE", value: e.target.value })
          }}
        />
      </td>
      <td>
        <input
          type="number"
          id={`autoship-${id}`}
          className="form-control"
          value={new_autoship_monthly}
          onChange={e => {
            console.log(e.target.value)
            send({ type: "AUTOSHIP_UPDATE", value: e.target.value })
          }}
        />
      </td>
      <td>{moneyFormat(revenue_share)}</td>
      <td>{`${supply_size} days`}</td>
    </tr>
  )
}

const ExtraCredit = ({
  total_customers,
  new_customers,
  subscription_customers,
  rev_share_array,
}) => (
  <>
    <tr>
      {[1, 2, 3].map(key => (
        <td index={key}>
          <p>
            {/* customers&nbsp; */}# of Customers
            <pre>
              {JSON.stringify(
                new_customers.map(item => item * key),
                null,
                2
              )}
            </pre>
            {/* {new_customers * key} */}
          </p>
        </td>
      ))}
    </tr>
    <tr>
      {[1, 2, 3].map(key => (
        <td index={key}>
          <p>
            {/* addl. customers&nbsp; */}
            Subscription Customers
            <pre>
              {JSON.stringify(
                subscription_customers.map(item => item * key),
                null,
                2
              )}
            </pre>
            {/* {subscription_customers * key} */}
          </p>
        </td>
      ))}
    </tr>
    <tr>
      {[1, 2, 3].map(key => (
        <td index={key}>
          <p>
            Total Customers
            <pre>
              {JSON.stringify(
                total_customers.map(item => item * key),
                null,
                2
              )}
            </pre>
            {/* {new_customers + subscription_customers * key} */}
          </p>
        </td>
      ))}
    </tr>
    <tr>
      <td>
        <p>
          <pre>{JSON.stringify(rev_share_array, null, 2)}</pre>
        </p>
      </td>
    </tr>
    <tr>
      {[1, 2, 3].map(key => (
        <td index={key}>
          <p>
            Profit Share by product
            <pre>
              {JSON.stringify(
                total_customers.map((item, index) =>
                  moneyFormat(item * key * rev_share_array[index])
                ),
                null,
                2
              )}
            </pre>
            {/* {new_customers + subscription_customers * key} */}
          </p>
        </td>
      ))}
    </tr>
  </>
)

const Calculator = () => {
  const products = useStrapiProducts()
  const [state, send] = useMachine(calculatorMachineFactory(products))
  const new_customers = state.context.products
    .map(product => product.ref.state.context)
    .map(({ new_customers_weekly }) => new_customers_weekly * 4 * 12)
  // .reduce((accumulator, currentValue) => accumulator + currentValue)
  const subscription_customers = state.context.products
    .map(product => product.ref.state.context)
    .map(({ new_customers_weekly, new_autoship_monthly }) => {
      return parseInt(
        new_customers_weekly * 4 * (new_autoship_monthly / 100) * 12
      )
    })
  const rev_share_array = state.context.products
    .map(product => product.ref.state.context)
    .map(({ revenue_share, supply_size }) => {
      console.log("supply", supply_size)
      switch (supply_size) {
        case 90:
          return revenue_share / 3
        case 60:
          return revenue_share / 2
        case 30:
          return revenue_share
        default:
          return 0
      }
    })
  const total_customers = new_customers.map(
    (item, index) => item + subscription_customers[index]
  )
  // .reduce((accumulator, currentValue) => accumulator + currentValue)
  return (
    <>
      {/* <pre>{JSON.stringify(products, null, 2)}</pre> */}
      <form
        onSubmit={e => {
          // console.log("FORMSUBMIT CREATE_ACCOUNT")
          e.preventDefault()
          send("CREATE_ACCOUNT")
        }}
      >
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Product</th>
              <th scope="col">Image</th>
              <th scope="col">Number/Week</th>
              <th scope="col">Autoship Conversion %</th>
              <th scope="col">Revenue Share</th>
              <th scope="col">Supply Size</th>
            </tr>
          </thead>
          <tbody>
            {state.context.products.map((product, index) => (
              <Product key={index} product={product} />
            ))}
          </tbody>
        </table>

        <table class="table">
          <thead>
            <tr>
              <th scope="col">Year 1</th>
              <th scope="col">Year 2</th>
              <th scope="col">Year 3</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {[1, 2, 3].map(key => (
                <td index={key}>
                  <p>
                    {moneyFormat(
                      total_customers
                        .map(
                          (item, index) => item * key * rev_share_array[index]
                        )
                        .reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue
                        )
                    )}
                  </p>
                </td>
              ))}
            </tr>
            <ExtraCredit
              total_customers={total_customers}
              new_customers={new_customers}
              subscription_customers={subscription_customers}
              rev_share_array={rev_share_array}
            />
          </tbody>
        </table>
      </form>
      <pre>{JSON.stringify(state.context, null, 2)}</pre>
    </>
  )
}

const IndexPage = () => {
  return (
    <Layout title="Home Page">
      <div className="row">
        <div className="col">
          <h1>Welcome</h1>
          <Calculator />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

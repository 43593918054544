// import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const useStrapiProducts = () => {
  const { allStrapiProduct } = useStaticQuery(
    graphql`
      query StrapiProducts {
        allStrapiProduct {
          edges {
            node {
              id
              title
              supply_size
              revenue_share
              # new_customers_monthly
              new_customers_weekly
            }
          }
        }
      }
    `
  )
  return allStrapiProduct.edges.map(item => item.node)
}
